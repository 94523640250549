import React from 'react';
import { X } from 'lucide-react';
import Link from 'next/link';

import { cn } from '@skybeam/shared-utils/classnames/cn';
import { Button } from '@skybeam/shared-ui-kit/ui/button';
import { NavItem } from './nav-item';
import { ReactComponent as Logo } from '../../../../public/skybeam-logo-with-text-blue.svg';

import styles from './navbar.module.css';

interface NavbarMobileProps {
  navbarMode: 'light' | 'dark' | 'white';
  setClose: VoidFunction;
}

const navItems = [
  { link: '/insights', label: 'Insights Hub' },
  { link: '/blog', label: 'Blog' },
  { link: '/pricing', label: 'Pricing' },
  { link: '/about', label: 'About Us' },
];

export const NavbarMobile: React.FC<NavbarMobileProps> = ({
  navbarMode,
  setClose,
}) => {
  const dashboardUrl = process.env.NEXT_PUBLIC_DASHBOARD_URL;

  return (
    <>
      <div
        data-test-mobile-navbar
        className={cn(
          styles.navbarMobile,
          'z-[100] flex-col rounded-b-[16px] border-t border-transparent border-b transition-colors duration-500 py-2 tablet:py-5 px-10',
          styles[navbarMode],
        )}
      >
        <div className="flex items-center justify-between h-[34px] tablet:h-[38px]">
          <div className="flex items-center tablet:gap-x-8 gap-x-4 h-8">
            <X className={styles.closeButton} onClick={setClose} />
            <Link href="/">
              <Logo id="skybeam-logo" className={styles.mobileLogo} />
            </Link>
          </div>
          <div className="hidden tablet:flex justify-center gap-x-2">
            <Link href={`${dashboardUrl}/sign-in`} className={cn(styles.link)}>
              <Button
                variant="link"
                className={cn(
                  'h-[38px] hover:no-underline font-proximaNova text-[15px] font-[500]',
                  navbarMode === 'white' ? 'text-[#0A0A0A]' : 'text-white',
                )}
              >
                Log In
              </Button>
            </Link>
            <Link href={`${dashboardUrl}/sign-up`}>
              <Button
                className={cn(
                  'h-[38px] rounded-lg font-proximaNova text-[15px] font-[500]',
                  navbarMode === 'dark'
                    ? 'marketing-primary-button'
                    : 'marketing-tertiary-button',
                )}
                variant="secondary"
              >
                Start Now
              </Button>
            </Link>
          </div>
        </div>
        <div className="flex flex-col pt-5">
          {navItems.map(({ link, label }) => (
            <NavItem key={link} href={link} onClick={setClose}>
              {label}
            </NavItem>
          ))}
        </div>
        <div className="flex tablet:hidden justify-center gap-x-2">
          <Link href={`${dashboardUrl}/sign-in`} className={cn(styles.link)}>
            <Button
              data-test-sign-in-btn
              className={cn(
                'h-9 w-full hover:no-underline font-proximaNova text-[15px] font-[500]',
                navbarMode === 'white' ? 'text-[#0A0A0A]' : 'text-white',
              )}
              variant="link"
            >
              Log In
            </Button>
          </Link>
          <Link href={`${dashboardUrl}/sign-up`}>
            <Button
              data-test-sign-up-btn
              className={cn(
                'rounded-lg w-full font-proximaNova text-[15px] font-[500]',
                navbarMode === 'dark'
                  ? 'marketing-primary-button'
                  : 'marketing-tertiary-button',
              )}
              variant="secondary"
            >
              Start Now
            </Button>
          </Link>
        </div>
      </div>
      <div
        className="fixed inset-0 z-[9] bg-black bg-opacity-50"
        onClick={setClose}
      />
    </>
  );
};
